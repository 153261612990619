import { Grid, Row, Col, Alert } from '@freecodecamp/react-bootstrap';
import type { TFunction } from 'i18next';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import DonateForm from '../components/Donation/donate-form';
import {
  DonationText,
  DonationOptionsAlertText,
  DonationFaqText
} from '../components/Donation/donation-text-components';

import { Spacer, Loader } from '../components/helpers';
import CampersImage from '../components/landing/components/campers-image';
import { signInLoadingSelector, userSelector, executeGA } from '../redux';
import Payment from '../components/landing/components/payment';

export interface ExecuteGaArg {
  type: string;
  data: {
    category: string;
    action: string;
    nonInteraction?: boolean;
    label?: string;
    value?: number;
  };
}
interface PaymentPageProps {
  executeGA: (arg: ExecuteGaArg) => void;
  isDonating?: boolean;
  showLoading: boolean;
  t: TFunction;
}

const mapStateToProps = createSelector(
  userSelector,
  signInLoadingSelector,
  ({ isDonating }: { isDonating: boolean }, showLoading: boolean) => ({
    isDonating,
    showLoading
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ executeGA }, dispatch);

function PaymentPage({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  executeGA = () => {},
  isDonating = false,
  showLoading,
  t
}: PaymentPageProps) {
  useEffect(() => {
    executeGA({
      type: 'event',
      data: {
        category: 'Donation View',
        action: `Displayed donate page`,
        nonInteraction: true
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleProcessing(duration: string, amount: number, action: string) {
    executeGA({
      type: 'event',
      data: {
        category: 'Donation',
        action: `donate page ${action}`,
        label: duration,
        value: amount
      }
    });
  }

  return <>
    <Helmet>
      <title>Extraise</title>
      <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
      <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
      <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
      <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
      <script defer key="popper" src="/assets/js/popper.min.js" />
      <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
      <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
      <script defer key="aos" src="/assets/js/aos.js" />
      <script defer key="splitting" src="/assets/js/splitting.js" />
      <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
      <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
      <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
      <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
      <script defer key="parallax" src="/assets/js/parallax.min.js" />
      <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
      <script defer key="gmaps" src="/assets/js/gmaps.js" />
      <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
      <script defer key="custom" src="/assets/js/custom.js" />
    </Helmet>

    <main>
      <Payment />
    </main>
  </>
}

PaymentPage.displayName = 'PaymentPage';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PaymentPage));
