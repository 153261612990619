import React from 'react';
import { apiLocation } from '../../../../../config/env.json';

const Payment = (): JSX.Element => {

  const quarterly = () => {
    location.href = apiLocation + "/donate/stripe-session?package=quarterly";
  }
  const monthly = () => {
    location.href = apiLocation + "/donate/stripe-session?package=monthly";
  }
  const annually = () => {
    location.href = apiLocation + "/donate/stripe-session?package=annually";
  }

  return (
    <>
      <section id="feature_section" className="feature_section sec_ptb_30 deco_wrap clearfix" style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#101116",
        color: "#fff"
      }}>
        <div className="container">
          <div className="feature_item">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-lg-5 col-md-7 col-sm-9">
                <div className="section_title decrease_size ml--30 c_slide_in">
                  <h2 className="title_text mb-30 c_slide_in_wrap1" style={{ textAlign: "center" }}>
                    <span className="c_slide_in_wrap2" style={{ margin: "0 auto" }}>
                      <span className="c_slide_in_wrap3" style={{ color: "#fff" }}>
                        <span style={{ color: "#f99141" }}>Be</span>come <span style={{ color: "#f99141" }}>Pro</span>grammer!
                      </span>
                    </span>
                  </h2>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={300}>
                    <div className="item_icon icon_purple">
                      <i className="icon-ring" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title" style={{ marginBottom: "0px", color: "#ddd" }}>Career Fortune</h3>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        Our learners work at Amazon, Google, Spotify and other top tech companies.
                      </p>
                    </div>
                  </div>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={400}>
                    <div className="item_icon icon_yellow">
                      <i className="icon-verified-avatar" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title" style={{ marginBottom: "0px", color: "#ddd" }}>Global Reach</h3>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        Lakhs across 150+ countries have used Pro to improve technical skills.
                      </p>
                    </div>
                  </div>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={300}>
                    <div className="item_icon icon_purple">
                      <i className="icon-ring" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title" style={{ marginBottom: "0px", color: "#ddd" }}>Trusted Partner</h3>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        IBM, Facebook, BP and others use Pro to train their teams.
                      </p>
                    </div>
                  </div>
                  <div className="child_feature_2 clearfix" data-aos="fade-up" data-aos-delay={300}>
                    <div className="item_icon icon_purple">
                      <i className="icon-ring" />
                    </div>
                    <div className="item_content">
                      <h3 className="item_title" style={{ marginBottom: "0px", color: "#ddd" }}>Certifications</h3>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        Get proof of your accomplishment with certificates for each path you complete.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-7 col-sm-9">
                <div className="table_wrap">
                  <table className="table m-0" style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 1em"
                  }}>
                    {/* <thead>
                        <tr style={{ backgroundColor: "#dfeefb" }}>
                            <td>Plan</td>
                            <td>Amount</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead> */}
                    <tbody style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "30px" }}>
                      <tr style={{ backgroundColor: "#fff" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <td>
                            <span className="td_title">Monthly</span><span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Advanced</span><br />
                            <span>$ 69 billed monthly</span>
                          </td>
                          <td>
                            <span className="td_title">
                              <span style={{ backgroundColor: "rgb(120, 57, 248)", color:"#fff", display: "inline-block", fontSize: "13px", padding: "0px 10px", fontWeight: "bold" }}>Save $ 130</span><br />
                              {/* Base Price $ 199 */}
                              <span style={{ fontWeight: "bold", fontSize: "18px" }}>$ 69 / Month</span>
                            </span>
                          </td>
                          <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                            <button onClick={monthly} style={{ border: "2px solid", padding: "0 10px", marginTop:"25px", lineHeight: "30px" }}>Try for Free</button>
                          </td>
                        </div>
                        <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Live MasterClasses</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Real Projects</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Peer Support</span>
                        </div>
                      </tr>
                      <tr style={{ backgroundColor: "rgb(255, 180, 121)" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <td>
                            <span className="td_title">Quarterly</span><span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "rgb(255 135 41)", padding: "6px", margin: "6px" }}>Beginner</span><br />
                            {/* Base Price $ 199 Quaterly / $ 799 Anually */}
                            <span>$ 69 billed Quarterly</span>
                          </td>
                          <td>
                            <span className="td_title">
                              <span style={{ backgroundColor: "#fff", display: "inline-block", fontSize: "13px", padding: "0px 10px", fontWeight: "bold" }}>Save $ 130</span><br />
                              <span style={{ fontWeight: "bold", fontSize: "18px" }}>$ 23 / Month</span>
                            </span>
                          </td>
                          <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                            <button onClick={quarterly} style={{ border: "2px solid", padding: "0 10px", marginTop:"25px", lineHeight: "30px" }}>Try for Free</button>
                          </td>
                        </div>
                        <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "rgb(255 135 41)", padding: "6px", margin: "6px" }}>Certification</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "rgb(255 135 41)", padding: "6px", margin: "6px" }}>Projects</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "rgb(255 135 41)", padding: "6px", margin: "6px" }}>Interview Prep</span>
                        </div>
                      </tr>
                      <tr style={{ backgroundColor: "#fff" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <td>
                            <span className="td_title">Anually</span><span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Beginner</span><br />
                            <span>$ 149 billed Anually</span>
                          </td>
                          <td>
                            <span className="td_title">
                              <span style={{ backgroundColor: "rgb(120, 57, 248)", color:"#fff", display: "inline-block", fontSize: "13px", padding: "0px 10px", fontWeight: "bold" }}>Save $ 650</span><br />
                              {/* Base Price $ 449 */}
                              <span style={{ fontWeight: "bold", fontSize: "18px" }}>$ 12 / Month</span>
                            </span>
                          </td>
                          <td style={{ textAlign: "right", verticalAlign: "middle" }}>
                            <button onClick={annually} style={{ border: "2px solid", padding: "0 10px", marginTop:"25px", lineHeight: "30px" }}>Try for Free</button>
                          </td>
                        </div>
                        <div style={{ marginTop: "-10px", marginBottom: "10px" }}>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Certification</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Projects</span>
                          <span style={{ fontSize: "12px", borderRadius:'5px', backgroundColor: "#cee1a8a3", padding: "6px", margin: "6px" }}>Interview Prep</span>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

Payment.displayName = 'Payment';
export default Payment;
